import { defineStore } from 'pinia'
import { type IInfo, IHM_SVC } from "../api/ihm"


interface IInfoState {
    info: IInfo | null
}

export const useInfosStore = defineStore('infos', {
    state: (): IInfoState => ({
        info: null
    }),
    getters: {
    },
    actions: {
        async fetchInfos(info: IInfo) {
            try {
                this.info = await IHM_SVC.getInfos();
            } catch (error) {
                console.error(error)
            }
        }
    }
})