<template>
  <section style="text-align: center; margin-right: 10px; ">
    <VRow>
    <span class="is-size-7">{{ now.format("DD/MM/YYYY") }} </span> <br>
    <span style="font-weight: bold; color: #3949AB">{{
      now.format("HH:mm:ss")
    }}</span>
    </VRow>
    <VRow>
    <span class="is-size-7">{{ "Version : " }}</span><span class="is-size-7">{{ info?.version_id }}</span> <br>
  </VRow>
  <VRow>
    <span class="is-size-7">{{ "Date : " }}</span><span class="is-size-7">{{ info?.date }}</span> <br>
  </VRow>
  </section>
</template>

<script setup>
import moment from "moment"
import { useInfosStore } from "@/store/infos";

const { info } = storeToRefs(useInfosStore());

const now = ref(moment())
let dateInterval = null
onMounted(() => {
  dateInterval = setInterval(() => {
    now.value = moment()
  }, 1000)
})
onBeforeUnmount(() => {
  clearInterval(dateInterval)
})

</script>
