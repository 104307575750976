<template>
  <v-app
    style="
      background: rgb(255, 255, 255);
      background: linear-gradient(
        175deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(232, 232, 232, 1) 57%,
        rgba(217, 217, 217, 1) 100%
      );
    "
  >
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in navItems"
          :key="index"
          :to="item.path"
          :disabled="item.disable"
          color="indigo-darken-1"
        >
          <template #prepend>
            <v-icon :icon="item.icon" color="indigo-darken-1" />
          </template>

          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-btn class="homeBtn" @click="goHome()">
        <img
          src="~assets/images/vinci_autoroutes.png"
          style="max-width: 120px; max-height: 120px"
        />
      </v-btn>

      <v-select
        v-model="site"
        item-title="name"
        item-value="slug"
        :items="sites"
        persistent-hint
        return-object
        single-line
        style="max-width: 150px; margin-top: 20px"
        density="compact"
        variant="outlined"
      />

      <v-spacer />
      <UtilsClock />
    </v-app-bar>
    <v-main>
      <v-container :fluid="true">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { useSitesStore } from "@/store/sites";
import { useInfosStore } from "@/store/infos";
import { useInfraStore } from "@/store/infra";

const { sites, site } = storeToRefs(useSitesStore());
let sitesStore = useSitesStore();

let interval = null;
const clipped = false;
const drawer = ref(true);
const miniVariant = false;
const router = useRouter();
const navItems = computed(() => [
  {
    id: 1,
    title: "Evenements",
    path: "/evenements",
    icon: "mdi-map-marker-radius",
    disable: !(site?.value?.events_use_case ?? false)
  },
  {
    id: 2,
    title: "Péages",
    path: "/peages",
    icon: "mdi-boom-gate-up",
    // disable: !site.value.tolls_use_case
    disable: !(site?.value?.tolls_use_case ?? false)
  },
  {
    id: 3,
    title: "Voies de Bus",
    path: "/voies-de-bus",
    icon: "mdi-bus",
    // disable: !site.value.bus_use_case
    disable: !(site?.value?.bus_use_case ?? false)
  },
  {
    id: 4,
    title: "PBR",
    path: "/pbr",
    icon: "mdi-camera",
    disable: false
  },
  {
    id: 5,
    title: "UBRs",
    path: "/ubrs",
    icon: "mdi-wifi",
    disable: false
  },
  {
    id: 6,
    title: "Caméras",
    path: "/cameras",
    icon: "mdi-camera",
    disable: false
  },
  {
    id: 7,
    title: "Simulateur Events",
    path: "/simulateur-events",
    icon: "mdi-cube-scan",
    disable: !(site?.value?.events_use_case ?? false)
  },
  {
    id: 8,
    title: "Simulateur Péages",
    path: "/simulateur-peages",
    icon: "mdi-cube-scan",
    disable: !(site?.value?.tolls_use_case ?? false)
  },
  {
    id: 9,
    title: "Simulateur Bus",
    path: "/simulateur-bus",
    icon: "mdi-cube-scan",
    disable: !(site?.value?.bus_use_case ?? false)
  },
  {
    id: 10,
    title: "Simulateur PBR",
    path: "/simulateur-pbr",
    icon: "mdi-cube-scan",
    disable: false
  },
  {
    id: 11,
    title: "Outils MAP",
    path: "/outils-map",
    icon: "mdi-cube-scan",
    disable: false
  },
  {
    id: 12,
    title: "ITS",
    path: "/its",
    icon: "mdi-information",
    disable: false
  },
  {
    id: 13,
    title: "Réglages",
    path: "/reglages",
    icon: "mdi-cog",
    disable: false,
  },
  {
    id: 134,
    title: "Test",
    path: "/test",
    icon: "mdi-cog",
    disable: false,
  },
]);


const infraStore = useInfraStore();

onMounted(async() => {
  await init();
});

function updateInfra(){
  // infraStore.getTollStations();
  // infraStore.getTollGates();
  // infraStore.getTollZones();
  infraStore.fetchRoadRoads();
  infraStore.fetchRoadLanes();
  infraStore.fetchRoadZones();
  infraStore.fetchCameras();
  infraStore.fetchUbrs();
  // getUbrs();
  // getCameras();
}

async function init() {
  await sitesStore.fetchSites();
  await useInfosStore().fetchInfos();
  updateInfra();

  interval = setInterval(() => {
    updateInfra();
  }, 1000000);
}

function goHome() {
  router.push({ path: "/" });
}

onBeforeUnmount(() => {
  clearInterval(interval);
});
</script>

<style>
.vue2leaflet-map {
  z-index: 1;
}
html {
  overflow-y: auto !important;
}

.scrollable-content {
  overflow-y: auto !important;
}
::-webkit-scrollbar {
  width: 7px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3949ab;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a237e;
}
.v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > td,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px !important;
}
</style>
